import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [0,2,3,5];

export const dictionary = {
		"/": [~6],
		"/auth/invite": [8],
		"/auth/oauth-error": [~9],
		"/bundles": [~10],
		"/bundles/[bundle]": [11,[2]],
		"/courses": [~12],
		"/courses/[course]": [~13,[3]],
		"/courses/[course]/lessons": [14,[3]],
		"/courses/[course]/review": [15,[3]],
		"/pay": [16],
		"/privacy": [~17],
		"/purchase-success": [~18],
		"/puzzles": [~19,[4]],
		"/puzzles/[puzzle]": [~20,[4]],
		"/simulator": [~21],
		"/social-media-simulator": [~22],
		"/terms": [~23],
		"/worksheets": [~24],
		"/worksheets/[worksheet]": [25,[5]],
		"/worksheets/[worksheet]/review": [~26,[5]],
		"/[content_page]": [~7]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';